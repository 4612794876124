export default [
  {
    title: "Ana Sayfa",
    route: "anasayfa",
    icon: "HomeIcon",
  },
  {
    title: "Kullanıcılar",
    route: "kullanicilar",
    icon: "UsersIcon",
  },
  {
    title: "Kısayol Butonları",
    route: "favoriurunbutonlari",
    icon: "KeyIcon",
  },
];
